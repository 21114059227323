<template>
    <div class="Transport">
        <div class="topsBox">
            <div class="trailerServer">
                <div class="selectItem">
                    <div class="ipts">
                        <el-input v-model="businessNo" placeholder="请输入业务编号/订单号/提单号/箱号"></el-input>
                    </div>
                </div>
                <div class="selectItem">
                    <div class="ipts">
                        <el-date-picker v-model="dataVal" type="daterange" range-separator="-" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="selectItem">
                    <div class="ipts">
                        <el-input v-model="pointAddressVal" placeholder="门点地址"></el-input>
                    </div>
                </div>
                <div class="selectItem">
                    <span>港口</span>
                    <div class="ipt">
                        <el-select v-model="portVal" placeholder="请选择港口">
                            <el-option v-for="item in portList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- <div class="selectItems">
                    <p>跟踪状态</p>
                    <div class="ipt">
                        <el-select v-model="trackStatusVal" placeholder="跟踪状态">
                            <el-option v-for="item in trackStatusList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->
                <!-- <div class="selectItems">
                <span>产品</span>
                <div class="ipt">
                    <el-input v-model="pointAddressVal" placeholder="请选择产品"></el-input>
                </div>
            </div> -->
            </div>
            <div class="trailerServer">

                <!-- <div class="selectItem">
                    <span>产品</span>
                    <div class="ipt">
                        <el-select v-model="productVal" placeholder="请选择产品">
                            <el-option v-for="item in productList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->

                <div class="searchBtn" @click="handleSearch">
                    <el-button type="primary" icon="el-icon-search">搜索</el-button>
                </div>
                <div class="resetBtn" @click="handleReset">
                    <span>重置</span>
                </div>
            </div>
        </div>

        <div class="bottomBox">
            <ul class="uls">
                <li class="lis" v-for="(itemXp, indexXp) in tableList" :key="indexXp">
                    <div class="itemLeft">
                        <div>
                            <span>提单号：</span>
                            <span style="color: #FFC13B;" v-if="itemXp.extList[0]">{{ itemXp.extList[0].billNo }}</span>
                        </div>
                        <div>
                            <span>箱单：</span>
                            <span style="color: #FFC13B;" v-if="itemXp.extList[0]">{{
                                itemXp.extList[0].packingNo
                            }}</span>
                        </div>
                        <div>
                            <span>封号：</span>
                            <span v-if="itemXp.extList[0]">{{ itemXp.extList[0].sealNo }}</span>
                        </div>
                        <div>
                            <span style="color: #FFC13B;" v-if="itemXp.extList[0]">{{
                                itemXp.extList[0].driverPlate
                            }}</span>
                        </div>
                        <div class="btns">
                            <div class="types" v-if="itemXp.extList[0]"><span>{{ itemXp.extList[0].boxType }}</span>
                            </div>
                            <div class="copys" @click="handleCopy(itemXp.extList[0])"><span>复制司机/箱资料</span></div>
                        </div>
                    </div>

                    <!-- <div class="content">
                        <div v-for="(item, index) in (2)" :key="index">

                            <div class="arrow" v-if="index != 0">
                                ↓
                            </div>
                            <div class="row" :class="{ 'reverse': index % 2 !== 0 }" :key="item">
                                <div class="item" v-for="(i, is) in 6" :key="is">
                                    <span v-if="index % 5 !== 0 && is != 5">←</span>
                                    {{ index * 5 + is }}
                                    <span v-if="index % 5 === 0 && is != 5">→</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="itemRight content">
                        <div class="plan">
                            <p>计划：</p>
                            <p>实际：</p>
                        </div>
                        <div style="flex: 1;">
                            <div v-for="(item, index) in 2" :key="index" style="height: 50%;">
                                <ul class="row" :class="{ 'reverse': index % 2 !== 0 }" :key="item">
                                    <li class="item" v-for="(i, is) in itemList" :key="i.name"
                                        v-show="is < 6 && index == 0">
                                        <div class="isArrive">
                                            <div class="notArrived" style="background-color: #1890FF;"
                                                v-if="itemXp.transport[is]">
                                                <i class="el-icon-check"></i>
                                            </div>
                                            <div class="notArrived" v-else></div>
                                        </div>
                                        <div class="isTit">
                                            <p>{{ i.name }}</p>
                                            <div class="img"
                                                @click="handleNode(i, itemXp.transport[is], itemXp.extList)"
                                                v-if="i.imgae">
                                                <i :class="i.imgae"></i>
                                            </div>
                                        </div>
                                        <div class="isTime">
                                            <p v-if="itemXp.transport[is]">
                                                {{ itemXp.transport[is].actualTime.split(' ')[0] }}
                                            </p>
                                            <p v-else>-</p>
                                            <p v-if="itemXp.transport[is]">
                                                {{ itemXp.transport[is].createTime.split(' ')[0] }}
                                            </p>
                                            <p v-else>-</p>
                                        </div>

                                        <div class="lines" v-if="is < 5"></div>
                                        <div class="lines2" v-if="is == 5"></div>
                                    </li>
                                    <li class="item" v-for="(i, is) in itemList" :key="is"
                                        v-show="is > 5 && index == 1">

                                        <div class="isArrive">
                                            <div class="notArrived" style="background-color: #1890FF;"
                                                v-if="itemXp.transport[is]">
                                                <i class="el-icon-check"></i>
                                            </div>
                                            <div class="notArrived" v-else></div>
                                        </div>
                                        <div class="isTit">
                                            <p>{{ i.name }}</p>
                                        </div>
                                        <div class="isTime">
                                            <p v-if="itemXp.transport[is]">
                                                {{ itemXp.transport[is].actualTime.split(' ')[0] }}
                                            </p>
                                            <p v-else>-</p>
                                            <p v-if="itemXp.transport[is]">
                                                {{ itemXp.transport[is].createTime.split(' ')[0] }}
                                            </p>
                                            <p v-else>-</p>
                                        </div>

                                        <div class="lines1" v-if="is > 6"></div>
                                        <div class="lines2" v-if="is == 5"></div>
                                    </li>
                                </ul>
                            </div>

                            <!-- <div style="height: 50%;">
                                <ul class="row">
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>接单</p>
                                        </div>
                                        <div class="isTime">
                                            <p v-if="itemXp.transport[0]">{{ itemXp.transport[0].actualTime ||
        '-'
}}
                                            </p>
                                            <p v-else>-</p>
                                            <p v-if="itemXp.transport[0]">{{ itemXp.transport[0].createTime ||
        '-'
}}</p>
                                            <p v-else>-</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>派车</p>
                                        </div>
                                        <div class="isTime">
                                            <p v-if="itemXp.transport[1]">{{ itemXp.transport[1].actualTime ||
        '-'
}}
                                            </p>
                                            <p v-else>-</p>
                                            <p v-if="itemXp.transport[1]">{{ itemXp.transport[1].createTime ||
        '-'
}}</p>
                                            <p v-else>-</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>提空</p>
                                            <div class="img" @click="handleNode()">
                                                <i class="el-icon-paperclip"></i>
                                            </div>
                                        </div>
                                        <div class="isTime">
                                            <p v-if="itemXp.transport[2]">{{ itemXp.transport[2].actualTime ||
        '-'
}}
                                            </p>
                                            <p v-else>-</p>
                                            <p v-if="itemXp.transport[2]">{{ itemXp.transport[2].createTime ||
        '-'
}}</p>
                                            <p v-else>-</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>到厂</p>
                                            <div class="img" @click="handleNode()">
                                                <i class="el-icon-paperclip"></i>
                                            </div>
                                        </div>
                                        <div class="isTime">
                                            <p v-if="itemXp.transport[3]">{{ itemXp.transport[3].actualTime ||
        '-'
}}
                                            </p>
                                            <p v-else>-</p>
                                            <p v-if="itemXp.transport[3]">{{ itemXp.transport[3].createTime ||
        '-'
}}</p>
                                            <p v-else>-</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>离厂</p>
                                            <div class="img" @click="handleNode()">
                                                <i class="el-icon-paperclip"></i>
                                            </div>
                                        </div>
                                        <div class="isTime">
                                            <p v-if="itemXp.transport[4]">{{ itemXp.transport[4].actualTime ||
        '-'
}}
                                            </p>
                                            <p v-else>-</p>
                                            <p v-if="itemXp.transport[4]">{{ itemXp.transport[4].createTime ||
        '-'
}}</p>
                                            <p v-else>-</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>进港</p>
                                            <div class="img" @click="handleNode()">
                                                <i class="el-icon-paperclip"></i>
                                            </div>
                                        </div>
                                        <div class="isTime">
                                            <p v-if="itemXp.transport[5]">{{ itemXp.transport[5].actualTime ||
        '-'
}}
                                            </p>
                                            <p v-else>-</p>
                                            <p v-if="itemXp.transport[5]">{{ itemXp.transport[5].createTime ||
        '-'
}}</p>
                                            <p v-else>-</p>
                                        </div>
                                        <div class="lines2"></div>
                                    </li>
                                </ul>
                            </div>
                            <div style="height: 50%;">
                                <ul class="row">
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>目的港卸船</p>
                                        </div>
                                        <div class="isTime">
                                            <p>14</p>
                                            <p>12-14 15:30</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>中转离港</p>
                                        </div>
                                        <div class="isTime">
                                            <p>14</p>
                                            <p>12-14 15:30</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>中转抵港</p>
                                        </div>
                                        <div class="isTime">
                                            <p>14</p>
                                            <p>12-14 15:30</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>始发港离港</p>
                                        </div>
                                        <div class="isTime">
                                            <p>14</p>
                                            <p>12-14 15:30</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>始发港靠泊</p>
                                        </div>
                                        <div class="isTime">
                                            <p>14</p>
                                            <p>12-14 15:30</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>码放</p>
                                        </div>
                                        <div class="isTime">
                                            <p>14</p>
                                            <p>12-14 15:30</p>
                                        </div>
                                        <div class="lines"></div>
                                    </li>
                                    <li class="item">
                                        <div class="isArrive">
                                            <div class="notArrived"></div>
                                        </div>
                                        <div class="isTit">
                                            <p>海放</p>
                                        </div>
                                        <div class="isTime">
                                            <p>14</p>
                                            <p>12-14 15:30</p>
                                        </div>
                                    </li>
                                </ul>
                            </div> -->
                        </div>

                    </div>

                </li>

            </ul>
        </div>
        <div>
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>
        </div>

        <MaskNode v-if="maskFlag" :changeClose="changeClose" :imageList="imageList" :maskName="maskName"
            :exListS="exListS"></MaskNode>
        <ArrivalMask v-if="ArrivalMaskFlag" :changeArrivalClose="changeArrivalClose"></ArrivalMask>
    </div>
</template>

<script>
import MaskNode from './MaskNode.vue';
import ArrivalMask from './ArrivalMask.vue'
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "Transport_Component",
    components: { MaskNode, ArrivalMask },
    data() {
        return {
            maskFlag: false,
            ArrivalMaskFlag: false,
            businessNo: "",//业务编号
            dataVal: "",//日期
            pointAddressVal: "",//门点地址
            trackStatusVal: "",//跟踪状态
            trackStatusList: [],//跟踪状态数据
            portVal: "",//港口
            portList: [{
                value: "宁波港",
                label: "宁波港"
            }, {
                value: "上海港",
                label: "上海港"
            }, {
                value: "南京港",
                label: "南京港"
            }, {
                value: "连云港",
                label: "连云港"
            }, {
                value: "青岛港",
                label: "青岛港"
            }, {
                value: "天津港",
                label: "天津港"
            }, {
                value: "太仓港",
                label: "太仓港"
            }, {
                value: "乍浦港",
                label: "乍浦港"
            }, {
                value: "深圳港",
                label: "深圳港"
            }, {
                value: "蛇口",
                label: "蛇口"
            }, {
                value: "盐田",
                label: "盐田"
            }, {
                value: "张家港",
                label: "张家港"
            }, {
                value: "常熟港",
                label: "常熟港"
            }, {
                value: "芜湖港",
                label: "芜湖港"
            }, {
                value: "合肥港",
                label: "合肥港"
            }, {
                value: "南通港",
                label: "南通港"
            }, {
                value: "广州港",
                label: "广州港"
            }, {
                value: "厦门港",
                label: "厦门港"
            }, {
                value: "江阴港",
                label: "江阴港"
            }, {
                value: "镇江港",
                label: "镇江港"
            }, {
                value: "独山港",
                label: "独山港"
            }, {
                value: "苏州港",
                label: "苏州港"
            }],//港口数据
            /* 
                                   */
            productVal: "",//产品
            productList: "",//产品数据
            tableList: [],
            transportList: [],
            itemList: [{
                name: "接单"
            }, {
                name: "派车"
            }, {
                name: "提空",
                imgae: "el-icon-paperclip",
            }, {
                name: "到厂",
                imgae: "el-icon-paperclip",
            }, {
                name: "离厂",
                imgae: "el-icon-paperclip",
            }, {
                name: "进港",
                imgae: "el-icon-paperclip",
            }, {
                name: "海放"
            }, {
                name: "码放"
            }, {
                name: "始发港靠泊"
            }, {
                name: "始发港离港"
            }, {
                name: "中转抵港"
            }, {
                name: "中转离港"
            }, {
                name: "目的港卸船"
            }],
            paging: {
                total: 0,
                size: 5,
            },
            maskName: "",
            imageList: [],
            exListS: [],
        }
    },
    created() {
        this.getTransportList()
    },
    methods: {
        getTransportList() {
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailerRouter/transportList`, {
                headers: { 'authorization': token }
            }

            ).then(res => {
                this.tableList = res.data.result.records;
                this.transportList = res.data.result.records.transport;
                this.paging.total = res.data.result.total;
                console.log(res.data.result)
            })
        },
        handleCopy(orderId) {
            console.log("@", orderId);
            let copeVal = [
                `提单号:${orderId.billNo}，
                箱单:${orderId.packingNo || ''}，
                封号:${orderId.sealNo || ''}，
                司机姓名${orderId.driverName || ''}，
                司机电话${orderId.driverMobile || ''}，
                车牌号:${orderId.driverPlate || ''}`
            ]
            // orderId.driverName, orderId.driverMobile, orderId.driverPlate

            var input = document.createElement("input"); // 创建input对象
            input.value = copeVal; // 设置复制内容
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message.success('复制成功！');
        },
        handleSearch() {
            console.log(this.dataVal);
            var dataVal1
            var dataVal2
            if (this.dataVal) {
                dataVal1 = this.dataVal[0];
                dataVal2 = this.dataVal[1];
            } else {
                dataVal1 = ''
                dataVal2 = ''
            }
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailerRouter/transportList`, {
                params: {
                    keywords: this.businessNo,
                    store_address: this.pointAddressVal,
                    begTime: dataVal1,
                    endTime: dataVal2
                },
                headers: { 'authorization': token }
            }).then(res => {
                this.tableList = res.data.result.records;
                this.transportList = res.data.result.records.transport;
                this.paging.total = res.data.result.total;
                console.log(res.data.result.records)
            })
        },
        handleReset() { //重置
            this.businessNo = this.dataVal = this.pointAddressVal = this.trackStatusVal = this.portVal = this.productVal = '';
            this.getTransportList()
        },

        handleNode(i, it, exlist) {
            this.imageList = it;
            console.log(exlist);
            if (!it) return
            if (i.name == '提空' || i.name == '到厂' || i.name == '离厂' || i.name == '进港') {
                this.maskName = i.name;
                this.exListS = exlist
                this.maskFlag = true;
            }
            // if (i.name == '进港') {
            //     this.ArrivalMaskFlag = true
            // }
        },
        changeClose() {
            this.maskFlag = false;
        },
        changeArrivalClose() {
            this.ArrivalMaskFlag = false
        },
        handleSizeChange(val) {
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailerRouter/transportList`, {
                params: {
                    pageNo: val,
                    keywords: this.businessNo,
                    store_address: this.pointAddressVal,
                    // begTime: dataVal1,
                    // endTime: dataVal2
                },
                headers: { 'authorization': token }
            }).then(res => {
                this.tableList = res.data.result.records;
                this.transportList = res.data.result.records.transport
                console.log(res.data.result.records)
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
    padding-top: 140rpx;

    .arrow-reverse {
        display: flex;
        flex-direction: row-reverse;
        margin-right: 80rpx;
    }

    .arrow-bottom {
        margin-left: 80rpx;
        width: 30rpx;
        height: 60rpx;
    }

    .row {
        display: flex;
        justify-content: space-between;
        height: 300rpx;

        .row-item {
            flex: .3;
            display: flex;
            align-items: center;
            position: relative;

            .arrow {
                padding-top: 50rpx;
                position: absolute;
                width: 60rpx;
                top: -35rpx;
                bottom: 0;
                margin: auto;
            }

            .arrow-left {
                left: -45rpx;
                margin: auto 0;
            }

            .arrow-right {
                right: -45rpx;
                margin: auto 0;
            }
        }
    }

    .reverse {
        flex-direction: row-reverse;
    }
}

.Transport {
    margin: 0 auto;
    // max-width: 1526px;
    min-height: 690px;
    // background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    // padding-top: 24px;

    .topsBox {
        padding: 24px 0 24px 0;
        background: #FFFFFF;
    }

    .trailerServer {
        padding: 0 24px;
        display: flex;
        margin-top: 16px;

        .selectItem {
            display: flex;
            align-items: center;
            min-width: 240px;
            height: 40px;
            // background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 32px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .ipts {
                width: 240px;
            }

            .el-date-editor {
                width: 100%;
            }

            .ipt {
                margin-left: 16px;
            }
        }

        .selectItem:nth-child(1) {
            margin-left: 0;
        }

        .selectItems {
            display: flex;
            align-items: center;
            margin-left: 32px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .ipt {
                width: 180px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                margin-left: 16px;
            }
        }

        .searchBtn {
            // margin-left: 68px;
            width: 120px;
            height: 40px;
            background: #FFC13B;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            overflow: hidden;

            .el-button {
                width: 100%;
                height: 100%;
                background: #FFC13B;
                border-color: transparent;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #2B2B2B;
                text-align: center;
                // line-height: 40px;
                padding: 0;
                line-height: 40px;
            }
        }

        .resetBtn {
            width: 80px;
            height: 40px;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #2B2B2B;
            border: 1px solid rgba(43, 43, 43, .4);
            margin-left: 32px;
            cursor: pointer;
        }
    }

    .trailerServer:nth-child(1) {
        margin-top: 0;
    }

    .bottomBox {
        margin-top: 16px;

        .uls {
            .lis {
                display: flex;
                height: 180px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                margin-top: 8px;

                .itemLeft {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    font-size: 14px;
                    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                    font-weight: 400;
                    padding: 24px 0 16px 16px;
                    width: 235px;
                    // border-right: 1px solid;

                    .btns {
                        display: flex;

                        .types {
                            font-size: 12px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #333333;
                            background: #F4F4F4;
                            border-radius: 2px 2px 2px 2px;
                            text-align: center;
                            line-height: 25px;
                        }

                        .copys {
                            width: 101px;
                            height: 25px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            border: 1px solid #1890FF;
                            text-align: center;
                            line-height: 25px;
                            font-size: 12px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #1890FF;
                            margin-left: 32px;
                            cursor: pointer;
                        }
                    }
                }

                .itemLeft::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 24px;
                    width: 1px;
                    height: calc(100% - 40px);
                    background-color: rgba(153, 153, 153, 0.30);
                }

                .itemRight {
                    display: flex;
                    flex: 1;
                    width: 100%;
                    height: 100%;

                    .plan {
                        width: 105px;
                        text-align: center;
                        font-size: 12px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        padding-top: 48px;
                    }

                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        height: 100%;

                        li {
                            position: relative;
                            // display: flex;
                            // flex: 1;
                            width: 14%;
                            // height: 50%;
                            padding-top: 12px;

                            .isArrive {
                                width: 50%;
                                text-align: center;

                                .notArrived {
                                    width: 20px;
                                    height: 20px;
                                    background: #CCCCCC;
                                    border-radius: 50%;
                                    margin: auto;
                                    text-align: center;
                                    line-height: 20px;
                                    color: #FFFFFF;
                                }
                            }

                            .isTit {
                                display: flex;
                                justify-content: center;
                                width: 50%;
                                font-size: 12px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                color: #333333;
                                text-align: center;
                                white-space: nowrap;

                                .img {
                                    width: 12px;
                                    height: 11px;
                                    margin-left: 4px;
                                    cursor: pointer;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }

                            .isTime {
                                width: 50%;
                                font-size: 12px;
                                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                                font-weight: 400;
                                text-align: center;
                                white-space: nowrap;

                                p:nth-child(1) {
                                    color: #333333;
                                }

                                p:nth-child(2) {
                                    color: #D4252C;
                                }
                            }

                            .lines {
                                position: absolute;
                                top: 22px;
                                right: -30%;
                                width: 90%;
                                height: 1px;
                                background-color: rgba(204, 204, 204, 1);
                            }

                            .lines1 {
                                position: absolute;
                                top: 22px;
                                right: -17%;
                                width: 80%;
                                height: 1px;
                                background-color: rgba(204, 204, 204, 1);
                            }

                            .lines2 {
                                position: absolute;
                                top: 22px;
                                right: 30%;
                                width: 30px;
                                height: 90px;
                                border-right: 1px solid rgba(204, 204, 204, 1);
                                border-top: 1px solid rgba(204, 204, 204, 1);
                                border-bottom: 1px solid rgba(204, 204, 204, 1);
                                border-radius: 0px 16px 16px 0px;
                            }


                        }

                        .reverse {
                            flex-direction: row-reverse;
                        }
                    }
                }
            }

            li:nth-child(1) {
                margin-top: 0;
            }
        }
    }

    .paging {
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin-top: 32px;
    }
}
</style>