<template>
    <div>
        <div>
            <Transport_Component></Transport_Component>
        </div>
    </div>
</template>

<script>
import Transport_Component from '../../components/YunShu/Transport_Component.vue'
export default {
    name: "Transport",
    components: { Transport_Component },
    data() {
        return {

        }
    },
}
</script>

<style>

</style>