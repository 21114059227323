<template>
    <div class="mask">
        <div class="arrrivaBox">
            <div class="tits">
                <p>进港数据对比</p>
                <p @click="handleClose"><i class="el-icon-close"></i></p>
            </div>

            <div class="tables">
                <div>
                    <el-table :data="tableData" border style="width: 100%" :header-row-style="{ height: '40px' }"
                        :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '40px' }">
                        <el-table-column prop="date" label="名称" width="180">
                        </el-table-column>
                        <el-table-column prop="name" label="订单信息" width="180">
                        </el-table-column>
                        <el-table-column prop="address" label="预录信息">
                        </el-table-column>
                        <el-table-column prop="address" label="港区信息">
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="botBtn">
                <div class="confirmButton">确定</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ArrivalMask",
    props: ['changeArrivalClose'],
    data() {
        return {
            tableData: []
        }
    },
    methods: {
        handleClose() {
            this.changeArrivalClose()
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table .el-table__cell {
    padding: 0;
}

.mask {
    position: absolute;
    width: 100%;
    height: 100%;

    .arrrivaBox {
        position: absolute;
        left: 50%;
        top: 90px;
        transform: translate(-50%);
        width: 1100px;
        height: 630px;
        background: #FFFFFF;

        .tits {
            height: 48px;
            line-height: 48px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(204, 204, 204, 1);
            padding: 0 24px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            p:nth-child(2) {
                cursor: pointer;
            }
        }

        .tables {
            margin-top: 16px;
            padding: 0 24px;
        }

        .botBtn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 62px;
            padding: 0 24px;

            .confirmButton {
                width: 68px;
                height: 30px;
                background: #1890FF;
                border-radius: 4px 4px 4px 4px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 30px;
                cursor: pointer;
            }
        }
    }
}
</style>