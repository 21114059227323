<template>
    <div class="mask">
        <div class="contentBox">
            <div class="tits">
                <p>{{ maskName }}节点</p>
                <p @click="handleClose" class="el-icon-close"></p>
            </div>

            <!-- <div class="tabBtn">
                <div class="btnItem"><span>装箱照(2)</span></div>
                <div class="btnItem"><span>装箱单(1)</span></div>
                <div class="btnItem"><span>提箱小票(1)</span></div>
            </div> -->

            <div class="boxedWall" v-if="imageLists.annex !== null">
                <div class="img" v-if="imageLists.annex !== null">
                    <img :src="`https://tu.ceecexpo.com/upload/${imageItem[imageNum]}`" alt="">
                </div>
                <div class="bot">
                    <div class="fileImg">
                        <img src="../../assets/ship/folder-download.png" alt="">
                    </div>
                    <div class="imgBtn">
                        <div class="btnItem" @click="handlePrevious"><i class="el-icon-arrow-left"></i></div>
                        <div class="btnItem"><i class="el-icon-minus"></i></div>
                        <div class="btnItem"><i class="el-icon-plus"></i></div>
                        <div class="btnItem" @click="handleNext"><i class="el-icon-arrow-right"></i></div>
                        <div class="btnItem">
                            <span>{{ imageNum + 1 }}</span> / <span>{{ imageItem.length }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tables">
                <div>
                    <el-table :data="tableExList" border style="width: 100%" :header-row-style="{ height: '32px' }"
                        :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '32px' }">
                        <el-table-column prop="packingNo" label="箱号" width="180">
                        </el-table-column>
                        <el-table-column prop="sealNo" label="封号" width="180">
                        </el-table-column>
                        <el-table-column prop="kgs" label="皮重(kg)">
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="btns">
                <div class="btn1"><span>复制信息</span></div>
                <div class="btn1" @click="handleClose" style="background: #1890FF;color: #FFFFFF;"><span>确定</span></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MaskNode",
    props: ['changeClose', 'imageList', 'maskName', 'exListS'],
    data() {
        return {
            tableData: [{
                date: 'MEDU2804910',
                name: 'FX26054927',
                address: '2280'
            },]
            ,
            imageLists: this.imageList,
            imageItem: [],
            imageNum: 0,
            tableExList: this.exListS
        }
    },
    mounted() {
        console.log(this.exListS);

        if (this.imageLists) {
            if (this.imageLists.annex) {
                this.imageItem = this.imageLists.annex.split(",");
            }

        }

        // console.log(this.imageItem[0]);
    },
    methods: {
        handlePrevious() {

            if (this.imageNum > 0) {
                this.imageNum--;

            } else {
                this.imageNum = this.imageItem.length - 1
            }
        },
        handleNext() {
            if (this.imageNum < this.imageItem.length - 1) {
                this.imageNum++;

            } else {
                this.imageNum = 0
            }

        },
        handleClose() { //关闭
            console.log("@@");
            this.changeClose()
        }
    },

}
</script>

<style lang="scss" scoped>
::v-deep .el-table .el-table__cell {
    padding: 0;
}

.mask {
    // position: relative;

    .contentBox {
        position: absolute;
        left: 50%;
        top: 90px;
        transform: translate(-50%);
        width: 1100px;
        // height: 576px;
        background: #FFFFFF;
        padding-bottom: 16px;

        .tits {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 48px;
            line-height: 48px;
            border-bottom: 1px solid rgba(204, 204, 204, 1);
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            padding-left: 24px;
            padding-right: 24px;

            p:nth-child(2) {
                cursor: pointer;
            }
        }

        .tabBtn {
            display: flex;
            margin-top: 16px;
            margin-left: 24px;

            .btnItem {
                // width: 68px;
                height: 30px;
                // background: #FFC13B;
                border: 1px solid #FFC13B;
                border-radius: 4px 4px 4px 4px;
                margin-left: 16px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                text-align: center;
                line-height: 30px;
                color: #FFC13B;
                padding: 0 9px;
                cursor: pointer;
            }

            .btnItem:nth-child(1) {
                margin-left: 0;
            }
        }

        .boxedWall {
            width: 1052px;
            height: 332px;
            background: rgba(244, 244, 244, 0.5);
            margin: 16px auto 0;

            .img {
                width: 225px;
                height: 300px;
                // border: 1px solid;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .bot {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid rgba(204, 204, 204, 1);
                height: 32px;

                .fileImg {
                    width: 19px;
                    height: 18px;
                    // background: #1890FF;
                    // border: 1px solid #1890FF;
                    margin: auto 0;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .imgBtn {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    .btnItem {
                        width: 32px;
                        height: 100%;
                        text-align: center;
                        line-height: 32px;
                        font-size: 14px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #333333;
                        border-left: 1px solid rgba(204, 204, 204, 1);
                        cursor: pointer;

                        i {
                            text-align: center;
                            line-height: 32px;
                            color: #333333;
                        }
                    }

                    .btnItem:nth-child(1) {
                        border-left: 0;
                    }
                }
            }
        }

        .tables {
            padding: 0 24px;
            margin-top: 8px;
        }

        .btns {
            display: flex;
            justify-content: flex-end;
            padding-right: 24px;
            margin-top: 16px;

            .btn1 {
                width: 68px;
                height: 30px;
                background: #FFC13B;
                border-radius: 4px 4px 4px 4px;
                text-align: center;
                line-height: 30px;
                font-size: 12px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                margin-left: 16px;
                cursor: pointer;
            }
        }
    }
}
</style>